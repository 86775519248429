// Penbox colors

import { genVuetifyVariations } from './util'

// Penbox colors

export const penboxBlue = '#03A9F4'
export const penboxBlueInfo = '#49a7da'
export const penboxDark = '#242b3d'
export const penboxGreen = '#0ec06e'
export const penboxLight = '#F8FAFC'
export const penboxLightLightBlue = '#ebf5fa'
export const penboxLightBlue = '#80d4ef'
export const penboxOrange = '#ff9f41'
export const penboxPurple = '#8B5CF6'
export const penboxRed = '#fe4e4e'
export const penboxTurquoise = '#00dfa7'
export const penboxYellow = '#ffc542'
export const penboxTeal = '#00A9DF'
export const penboxNewPrimary = '#5350FF'

// Material colors (only yse actual material color names here)
// https://vuetifyjs.com/en/styles/colors/#material-colors

export const green = genVuetifyVariations(penboxGreen)
export const red = genVuetifyVariations(penboxRed)
export const blue = genVuetifyVariations(penboxBlueInfo)
export const purple = genVuetifyVariations(penboxPurple)
export const deepPurple = genVuetifyVariations(penboxPurple)
export const yellow = genVuetifyVariations(penboxYellow)
export const teal = {
  base: penboxTeal,
  lighten1: '#F9FEFF',
}
// Vuetify theme colors (only use actual color names here)

export const primary = genVuetifyVariations(penboxBlue)
export const secondary = genVuetifyVariations(penboxOrange)
export const accent = genVuetifyVariations(penboxTurquoise)

export const error = red
export const info = blue
export const success = green
export const warning = yellow

// Vuetify anchor color

export const anchor = penboxBlueInfo

// Custom aliases

export const support = purple
